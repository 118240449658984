import React, { Component } from "react"
import { globalHistory } from "@reach/router"
import { navigate, Link } from "gatsby"
import { userLogin } from "../../utils/Auth"
import { RefreshContent } from "react-js-pull-to-refresh"
// @Material UI Core
import { Grid } from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import withStyles from "@material-ui/core/styles/withStyles"
// Material Kit Pro
import Card from "../Card/Card"
import CardHeader from "../Card/CardHeader"
import Button from "../CustomButtons/Button"
import CardBody from "../Card/CardBody"
import CustomInput from "../CustomInput/CustomInput"
import javascriptStyles from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles"
// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const style = {
  cardBorder: {
    marginBottom: "30px",
    padding: "20px",
    // maxWidth: "480px"
  },
  cardBorderMobile: {
    marginBottom: "30px",
    padding: "20px",
    maxWidth: "480px",
  },
  titleCenter: {
    textAlign: "center",
    padding: "20px",
  },
}

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      success: "",
      error: "",
      loading: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const key = event.target.name
    const value = event.target.value

    this.setState({
      [key]: value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    let self = this

    self.setState({
      loading: true,
    })

    userLogin(this.state.name, this.state.password, (error, response) => {
      if (error) {
        self.setState({
          loading: false,
          success: "",
          error: error,
        })
      } else {
        self.setState({
          loading: false,
          success: "Login Successful",
          error: "",
        })
        setTimeout(() => {
          navigate(
            globalHistory.location.state &&
              globalHistory.location.state.redirect != null // Checks null and undefined
              ? globalHistory.location.state.redirect
              : "/u/dashboard"
          )
        }, 1500)
      }
    })
  }

  render() {
    const { classes } = this.props
    const { loading, success, error, name, password } = this.state
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ padding: "1.5rem 0" }}
      >
        <Card style={style.cardBorderMobile}>
          <CardHeader
            plain
            color="primary"
            className={`${classes.textCenter} ${classes.cardLoginHeader}`}
          >
            <h5 className={classes.cardTitleWhite}>Log in</h5>
          </CardHeader>
          <div className={classes.textCenter} style={{ paddingTop: "20px" }}>
            <Link to="/register" replace>
              Don't have an account?
            </Link>
          </div>
          <form onSubmit={this.handleSubmit} style={{ textAlign: "center" }}>
            <CardBody className={classes.cardLoginBody}>
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  required: true,
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={"envelope"} />
                    </InputAdornment>
                  ),
                  value: name,
                  onChange: this.handleChange,
                  type: "text",
                  className: "form-control",
                  name: "name",
                  placeholder: "Username...",
                  autoFocus: false,
                }}
              />
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  required: true,
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={"lock"} />
                    </InputAdornment>
                  ),
                  name: "password",
                  value: password,
                  onChange: this.handleChange,
                  type: "password",
                  className: "form-control",
                  placeholder: "Password...",
                  autoFocus: false,
                }}
              />
              <div style={{ textAlign: "center" }}>
                {loading && <RefreshContent />}
                <p className="success">{success}</p>
                {error && (
                  <p
                    className="error"
                    dangerouslySetInnerHTML={{ __html: error }}
                  />
                )}
              </div>
            </CardBody>
            {!loading && !success && (
              <Button
                color="primary"
                // style={{ padding: "10px 50px 20px" }}
                size="lg"
                type="submit"
                // disabled={this.state.loading || this.state.success !== ""}
              >
                Login Now
              </Button>
            )}
            <div className={classes.textCenter} style={{ paddingTop: "20px" }}>
              <Link to="/password-reset-link" replace>
                <small>Trouble logging in?</small>
              </Link>
            </div>
          </form>
        </Card>
      </Grid>
    )
  }
}

export default withStyles(javascriptStyles)(Login)
