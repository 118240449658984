import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Login from "../components/Login"
import { isAuthenticated } from "../utils/Auth"
import { navigate } from "gatsby-link"

const LoginPage = (props) => {
  isAuthenticated() && navigate("/u/dashboard")

  return (
    <Layout>
      <SEO title="CCO Login" />
      <Login {...props} />
    </Layout>
  )
}
export default LoginPage
